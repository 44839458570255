
import { AvButton } from "@/components";
import ENUMS from "@/enums";
import { convertDateTimestampToString } from "@/services/utils";
import { INotification } from "@/store/firebase/firebaseState";
import {
  BBadge,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaBody,
  BRow,
  VBTooltip,
} from "bootstrap-vue";
import { Timestamp } from "firebase/firestore";
import { Component, Vue } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import { RouterLink } from "vue-router";

@Component({
  name: "NotificationsList",
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaBody,
    BCardBody,
    RouterLink,
    BBadge,
    AvButton,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
})
export default class NotificationsList extends Vue {
  $ENUMS = ENUMS;

  // Computeds
  get notifications(): INotification[] {
    return this.$store.getters["firebase/notifications"];
  }

  // Methods
  updateNotification(notification: INotification, read: boolean) {
    const n = {
      ...notification,
      Read: read,
    };
    this.$store.dispatch("firebase/updateNotification", n);
  }

  deleteNotification(notificationId: string) {
    this.$store.dispatch("firebase/deleteNotification", notificationId);
  }

  tagsColor(tag: string) {
    if (tag === "Dashboard") return "light-info";
    if (tag === "Valid. Marca") return "light-info";
    if (tag === "Usuário") return "light-info";
    if (tag === "Arquivo") return "light-warning";
    if (tag === "Adicionado") return "light-success";
    // if (tag === "Gaming") return "light-danger";
    // if (tag === "Video") return "light-warning";
    return "light-primary";
  }

  resolverType(notification: INotification): {
    title: string;
    categories: string[];
  } {
    if (notification.Type == "NotificacaoDashboard")
      return { title: "Dashboard Atualizado", categories: ["Dashboard"] };
    if (notification.Type == "NotificacaoValidacaoMarca")
      return {
        title: "Validação da Marca",
        categories: ["Valid. Marca", "Arquivo"],
      };
    if (notification.Type == "NotificacaoCreateUserFinish")
      return {
        title: "Usuário Criado com Sucesso",
        categories: ["Usuário", "Adicionado"],
      };
    return { title: notification.Message, categories: ["Outros"] };
  }

  getDateNotification(date: Timestamp): string {
    return convertDateTimestampToString(date);
  }
}
